
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { directUSAPICall } from "../../utils"
import CircularProgress from '@material-ui/core/CircularProgress';
import CurrencyFormat from '../../../../../app/modules/components/CurrencyFormat'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '80%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  dialogRoot: {
    width: '85% !important',
    height: '640px',
    //   display: 'flex',
    //   flexDirection: 'column',
  },
  menu: {
    width: 200,
  },
}));

export default function AlertDialog({
  showDialog,
  handleCancel,
}) {
  const [description, setDescription] = useState('')
  const [datas, setDatas] = React.useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    months: null,
    equipment: null
  })

  const classes = useStyles()
  function handleClose() {
    handleCancel(false);
  }

  useEffect(() => {
    // setAssetData(selectedItem.data)
    setEditData({})
    getdata()
  }, [])

  const getdata = async (id) => {
    setLoading(true)
    try {
      console.log('==============>')
      let options = {
        limit: -1,
        sort: '-id',
        fields: ['id,model,first_year_rent,second_year_rent,third_year_rent'],
        filter: {
          "status": {
            _neq: 'archived'
          }
        },
      }
      const result = await directUSAPICall().items('refurbished_model').readMany(options);
      if (result.data && result.data.length > 0) {
        setDatas(result.data);
        setLoading(false)
      } else {
        setDatas([])
        setLoading(false)
      }
    } catch (err) {
      throw err
    }

  }

  const submit = async () => {
    if (values.model) {
      let result = ""
      let param = {
        model: values.model,
        model_name: values.model ? values.model?.toUpperCase() : '',
        first_year_rent: values.first_year_rent || 0,
        second_year_rent: values.second_year_rent || 0,
        third_year_rent: values.third_year_rent || 0,
      }
      setLoading(true)
      if (values.id) {
        result = await directUSAPICall().items('refurbished_model').updateOne(values.id, param);
      } else {
        result = await directUSAPICall().items('refurbished_model').createOne(param);
      }
      if (result.id) {
        setValues(
          {
            model: '',
            model_name: '',
            first_year_rent: '',
            second_year_rent: '',
            third_year_rent: ''
          }
        )
        getdata();
      } else {
        setLoading(false)
      }
    }

  }

  const deleteData = async (id) => {
    let result = ""
    setLoading(true)
    if (id) {
      result = await directUSAPICall().items('refurbished_model').deleteOne(id);
      setTimeout(() => {
        getdata();
        setLoading(false)
      }, 500)
    }

  }

  const edit = (item) => {
    setEditMode(true)
    setValues(item)
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='lg'
        className='dialogRoot'
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle id="alert-dialog-title text-uppercase">Refurbished</DialogTitle>
        <DialogContent>

          <form
            autoComplete="off"
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
          // onSubmit={handleSubmit}
          >
            <div className="card card-custom gutter-b">
              <div className='card-body'>
                <div className="form-group row">

                  <div className='col-lg-3'>
                    <TextField
                      variant={"standard"}
                      required
                      label="Model"
                      id="Model"
                      className={classes.textField}
                      name={'model'}
                      value={values?.model || ''}
                      onChange={handleChange('model')}
                      margin="normal"
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className='col-lg-2'>
                    <CurrencyFormat
                      variant={"standard"}
                      value={values?.first_year_rent ? (values.first_year_rent) : ''}
                      id="first"
                      name="first_year_rent"
                      label="First year"
                      className={classes.textField}
                      name={'first_year_rent'}
                      onChange={handleChange('first_year_rent')}
                    />
                  </div>
                  <div className='col-lg-2'>
                    <CurrencyFormat
                      variant={"standard"}
                      value={values?.second_year_rent ? (values.second_year_rent) : ''}
                      id="second"
                      name="second_year_rent"
                      label="Second year"
                      className={classes.textField}
                      name={'second_year_rent'}
                      onChange={handleChange('second_year_rent')}
                    />
                  </div>
                  <div className='col-lg-2'>
                    <CurrencyFormat
                      variant={"standard"}
                      value={values?.third_year_rent ? (values.third_year_rent) : ''}
                      id="first"
                      name="third_year_rent"
                      label="Third year"
                      className={classes.textField}
                      name={'third_year_rent'}
                      onChange={handleChange('third_year_rent')}
                    />
                  </div>
                  <div className='col-lg-3'>
                    <Button
                      variant='contained'
                      onClick={() => setValues(null)}
                      color='secondary'
                      // disabled={loading}
                      className={classes.button}
                    >
                      Clear
                  </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {
                      <Button
                        variant='contained'
                        onClick={() => submit()}
                        color='primary'
                        // disabled={loading || !enableSubmission}
                        className={classes.button}
                      >
                        {values?.id ? 'Update' : 'Submit'}
                        {loading && (
                          <CircularProgress
                            size='1rem'
                            className={classes.buttonProgress}
                          />
                        )}
                      </Button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className='card-body pt-2'>
            {
              datas?.length > 0 ? datas.map((item, key) => (
                <div className="form-group row" key={key} style={{ marginBottom: '.6rem' }}>

                  <div className='col-lg-4'>
                    {item.model}
                  </div>
                  <div className='col-lg-2'>
                    {item.first_year_rent}
                  </div>
                  <div className='col-lg-2'>
                    {item.second_year_rent}
                  </div>
                  <div className='col-lg-2'>
                    {item.third_year_rent}
                  </div>
                  <div className='col-lg-2'>
                    <i className="fas fa-edit" onClick={() => edit(item)} />
                    <i className="fas fa-trash-alt" onClick={() => deleteData(item.id)} />
                  </div>
                </div>
              )) : null
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => handleClose(false)}
            color='secondary'
            // disabled={loading}
            className={classes.button}
          >
            Close
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
